import { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Grid,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import BuildIcon from "@mui/icons-material/Build";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import styles from "../components.module.sass";
import DesignerContext from "../../context/DesignerContext";
import { pulling_details_line } from "../../utils/data";
import { ImageProps, ProductProps } from "../../interfaces/interfaces";
import DataContext from "../../context/DataContext";
import { TitlebarImageList } from "../common/ImageList";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import AddCommentIcon from "@mui/icons-material/AddComment";

export const PullingDetailsTable = () => {
  const { pullingInfo, onEditPullingInfo } = useContext(DesignerContext);
  const { productOptions } = useContext(DataContext);
  const matches = useMediaQuery("(min-width:600px)");

  const handleAddLine = (index: number) => {
    if (index || index === 0) {
      let newPullingDetail = [
        ...pullingInfo.details.slice(0, index),
        pulling_details_line,
        ...pullingInfo.details.slice(index),
      ];
      onEditPullingInfo((prev) => {
        return {
          ...prev,
          details: newPullingDetail,
        };
      });
    } else {
      onEditPullingInfo((prev) => {
        return {
          ...prev,
          details: [...prev.details, pulling_details_line],
        };
      });
    }
  };

  const handleRemoveLine = (index: number) => {
    if (index || index === 0) {
      let newPullingDetail = pullingInfo.details.filter(
        (_, idx) => idx !== index
      );
      onEditPullingInfo((prev) => {
        return {
          ...prev,
          details: newPullingDetail,
        };
      });
    }
  };

  const handleEditLine = (index: number, name: string, value: any) => {
    let newPullingDetail = pullingInfo.details.map((line, idx) => {
      if (index === idx) {
        return {
          ...line,
          [name]: value,
        };
      }
      return line;
    });
    onEditPullingInfo((prev) => {
      return {
        ...prev,
        details: newPullingDetail,
      };
    });
  };

  const handleEditDetailLine = (
    indexLine: number,
    indexDetail: number,
    value: string
  ) => {
    let newDetails = pullingInfo.details.map((detail, idx) => {
      if (indexLine === idx) {
        return {
          ...detail,
          comment: detail.comment.map((itemDetail, idxDetail) => {
            if (indexDetail === idxDetail) {
              return value;
            }
            return itemDetail;
          }),
        };
      }
      return detail;
    });

    onEditPullingInfo((prev) => {
      return {
        ...prev,
        details: newDetails,
      };
    });
  };

  const handleRemoveComment = (indexLine: number, indexComment: number) => {
    let newDetails = pullingInfo.details.map((detail, idx) => {
      if (indexLine === idx) {
        return {
          ...detail,
          comment: detail.comment.filter((_, index) => index !== indexComment),
        };
      }
      return detail;
    });

    onEditPullingInfo((prev) => {
      return {
        ...prev,
        details: newDetails,
      };
    });
  };
  const handleDeleteImage = (indexLine: number, indexImage: number) => {
    let newDetails = pullingInfo.details.map((detail, idx) => {
      if (indexLine === idx) {
        return {
          ...detail,

          pictures: detail.pictures.filter((_, index) => index !== indexImage),
        };
      }
      return detail;
    });

    onEditPullingInfo((prev) => {
      return {
        ...prev,
        details: newDetails,
      };
    });
  };

  const handleEditImagelLine = (
    indexLine: number,
    indexDetail: number,
    value: ImageProps
  ) => {
    let newDetails = pullingInfo.details.map((detail, idx) => {
      if (indexLine === idx) {
        return {
          ...detail,
          pictures: detail.pictures.map((itemDetail, idxDetail) => {
            if (indexDetail === idxDetail) {
              return value;
            }
            return itemDetail;
          }),
        };
      }
      return detail;
    });
    onEditPullingInfo((prev) => {
      return {
        ...prev,
        details: newDetails,
      };
    });
  };

  const handleAddItem = (indexLine: number, name: "pictures" | "comment") => {
    let newDetails = pullingInfo.details.map((detail, idx) => {
      if (indexLine === idx) {
        return {
          ...detail,
          [name]: [...detail[name], ""],
        };
      }
      return detail;
    });
    onEditPullingInfo((prev) => {
      return {
        ...prev,
        details: newDetails,
      };
    });
  };

  const handleAddPicture = (indexLine: number) => {
    let newDetails = pullingInfo.details.map((detail, idx) => {
      if (indexLine === idx) {
        return {
          ...detail,
          pictures: [...detail["pictures"], { image: "", size: 1 }],
        };
      }
      return detail;
    });
    onEditPullingInfo((prev) => {
      return {
        ...prev,
        details: newDetails,
      };
    });
  };

  const defaultProps = {
    options: productOptions,
    getOptionLabel: (option: ProductProps) => option.name,
  };

  const defaultPropsPartNumber = {
    options: productOptions,
    getOptionLabel: (option: ProductProps) => option.partNumber,
  };

  return (
    <>
      <div className={styles.buttonTally}>
        <ButtonGroup
          variant="outlined"
          orientation={`${matches ? `horizontal` : `vertical`}`}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleAddLine(0)}
          >
            <BuildIcon fontSize="small" sx={{ mr: 1 }} />
            Add line
          </Button>
        </ButtonGroup>
      </div>
      {pullingInfo.details.length > 0 && (
        <div className={styles.tableContainer}>
          <table className={styles.tableTally}>
            <thead>
              <tr>
                <th>Actions</th>
                <th>Description</th>
                <th>Evidences</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              {pullingInfo.details.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className={styles["grid-col"]}>
                      <Tooltip title="Add above">
                        <IconButton
                          size="small"
                          sx={{ width: "2rem", marginBottom: "100px" }}
                          onClick={() => {
                            handleAddLine(index);
                          }}
                        >
                          <NorthEastIcon sx={{ color: "#FBAB53" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Add Underneath">
                        <IconButton
                          size="small"
                          sx={{ width: "2rem", marginBottom: "100px" }}
                          onClick={() => {
                            handleAddLine(index + 1);
                          }}
                        >
                          <SouthEastIcon sx={{ color: "#FBAB53" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Remove row">
                        <IconButton
                          size="small"
                          sx={{ width: "2rem" }}
                          onClick={() => handleRemoveLine(index)}
                        >
                          <DeleteOutlineOutlinedIcon
                            sx={{ color: "#CD1719" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </td>
                  <td style={{ width: "30%" }}>
                    <Autocomplete
                      id="product-selector"
                      value={item.description}
                      isOptionEqualToValue={(option, value) => {
                        return true;
                      }}
                      onChange={(_, newValue) =>
                        newValue &&
                        handleEditLine(index, "description", newValue)
                      }
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.partNumber}
                          </li>
                        );
                      }}
                      {...defaultPropsPartNumber}
                      disablePortal
                      sx={{ width: 300 }}
                      className={styles.textField}
                      renderInput={(params) => (
                        <TextField {...params} label="Part Number" />
                      )}
                    />
                    <Autocomplete
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      value={item.description}
                      isOptionEqualToValue={(option, value) => {
                        return true;
                      }}
                      onChange={(_, newValue) =>
                        newValue &&
                        handleEditLine(index, "description", newValue)
                      }
                      {...defaultProps}
                      disablePortal
                      className={styles.textField}
                      sx={{ width: 300, marginTop: "20px" }}
                      renderInput={(params) => (
                        <TextField {...params} label="Description" />
                      )}
                    />
                    <TextField
                      id="toolName"
                      label="Tool Name"
                      variant="outlined"
                      value={item.toolName}
                      className={styles.textField}
                      sx={{ width: 300, marginTop: "20px" }}
                      onChange={(event) =>
                        handleEditLine(index, "toolName", event.target.value)
                      }
                    />
                    <TextField
                      id="quantity"
                      type="number"
                      label="Quantity"
                      variant="outlined"
                      value={item.quantity}
                      className={styles.textField}
                      sx={{ width: 300, marginTop: "20px" }}
                      onChange={(event) =>
                        parseInt(event.target.value) >= 1 &&
                        handleEditLine(index, "quantity", event.target.value)
                      }
                    />
                  </td>
                  <td style={{ width: "30%", textAlign: "right" }}>
                    <Tooltip title="Add Image">
                      <IconButton
                        size="small"
                        sx={{
                          width: "2rem",
                          color: "green",
                          marginTop: "10px",
                        }}
                        onClick={() => {
                          handleAddPicture(index);
                        }}
                      >
                        <AddPhotoAlternateIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Grid container alignItems="center">
                      <TitlebarImageList
                        itemData={item.pictures}
                        index={index}
                        onChange={handleEditImagelLine}
                        handleDeleteImage={handleDeleteImage}
                      />
                    </Grid>
                  </td>
                  <td style={{ width: "30%", textAlign: "right" }}>
                    <Tooltip title="Add Comment">
                      <IconButton
                        size="small"
                        sx={{ width: "2rem", color: "green" }}
                        onClick={() => {
                          handleAddItem(index, "comment");
                        }}
                      >
                        <AddCommentIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Grid container alignItems="center">
                      {item.comment.map((comment, idx) => (
                        <>
                          <TextField
                            key={idx}
                            variant="outlined"
                            value={comment}
                            multiline
                            rows={3}
                            className={styles.textField}
                            style={{ width: "100%", marginTop: "10px" }}
                            onChange={(event) =>
                              handleEditDetailLine(
                                index,
                                idx,
                                event.target.value
                              )
                            }
                          />
                          <Tooltip title="Remove Comment">
                            <IconButton
                              size="small"
                              sx={{ width: "2rem" }}
                              onClick={() => handleRemoveComment(index, idx)}
                            >
                              <DeleteOutlineOutlinedIcon
                                sx={{ color: "#CD1719" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </>
                      ))}
                    </Grid>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

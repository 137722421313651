import React from "react";
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { DataProps, WbdItemProps } from "../../../../interfaces/interfaces";
import wbdImage from "../../../../utils/images/page1.png";
import slaImage from "../../../../utils/images/PCP.png";
import designImage from "../../../../utils/images/pcp_design.png";

const styles = StyleSheet.create({
  body: {
    margin: 0,
  },
  image: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    height: "100%",
    width: "100%",
  },
  sla: {
    position: "relative",
    top: 45,
    left: 150,
    width: "45px",
  },
  design: {
    position: "relative",
    top: 60,
    left: 144,
    width: "55px",
  },
  tool: {
    position: "relative",
    top: 60,
    left: 146,
    width: "50px",
  },
  toolTol: {
    position: "relative",
    top: 60,
    left: 87.5,
    width: "50px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  imageContainer: {
    position: "relative",
    width: "auto", // Set your image width
    height: "auto", // Set your image height
  },
  aux: {
    position: "relative",
    top: 60,
    left: 50,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  line: {
    position: "relative",
    top: 60,
    left: 150,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  lineTol: {
    position: "relative",
    top: 60,
    left: 90,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  description: {
    position: "relative",
    top: 60,
    left: 160,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  innerTool: {
    position: "relative",
    top: 60,
    left: 95,
    width: "50px",
    alignItems: "center",
    justifyContent: "center",
  },
  lineInner: {
    position: "relative",
    top: 60,
    left: 100,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionInner: {
    position: "relative",
    top: 60,
    left: 110,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  innerToolTol: {
    position: "relative",
    top: 60,
    left: 37,
    width: "50px",
    alignItems: "center",
    justifyContent: "center",
  },
  imageLeftTOL: {
    position: "absolute",
    top: 0,
    left: "33.35%", // Centra la línea horizontalmente
    width: "10px", // Ajusta el grosor de la línea
    height: "100%",
    backgroundColor: "white", // Color de la línea
    transform: "translate(-50%, 100%)", // Centra la línea horizontalmente en la posición especificada
  },
  lineImageLeftTOL: {
    position: "absolute",
    top: 0,
    left: "35%", // Centra la línea horizontalmente
    width: "1px", // Ajusta el grosor de la línea
    height: "100%",
    backgroundColor: "black", // Color de la línea
    transform: "translate(-50%, 80%)", // Centra la línea horizontalmente en la posición especificada
  },
  lineImageLastTOL: {
    height: "60px",
  },
  lineImageRightTOL: {
    position: "absolute",
    top: 0,
    left: "39%", // Centra la línea horizontalmente
    width: "1px", // Ajusta el grosor de la línea
    height: "100%", // Asegura que la línea cubra toda la altura de la pantalla
    backgroundColor: "black", // Color de la línea
    transform: "translate(-50%, 80%)", // Centra la línea horizontalmente en la posición especificada
  },
  imageRightTOL: {
    position: "absolute",
    top: 0,
    left: "39%", // Centra la línea horizontalmente
    width: "9.8px", // Ajusta el grosor de la línea
    height: "100%",
    backgroundColor: "white", // Color de la línea
    transform: "translate(-50%, 100%)", // Centra la línea horizontalmente en la posición especificada
  },
  lineInnerTol: {
    position: "relative",
    top: 60,
    left: 40,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionInnerTol: {
    position: "relative",
    top: 60,
    left: 50,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionTol: {
    position: "relative",
    top: 60,
    left: 100,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
  },
  tolDepth: {
    position: "relative",
    top: 60,
    left: 70,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export const WbdFirstPagePCP = ({
  tools,
  data: {
    basicInfo: { bhaInfo },
  },
}: {
  tools: WbdItemProps[];
  data: DataProps;
}) => {
  const startIndex = tools.findIndex((item) => item.tol === true);
  return (
    <Page style={styles.body}>
      <Image cache style={styles.image} src={wbdImage} fixed />
      <Image cache style={styles.sla} src={slaImage} fixed />
      <Image cache style={styles.design} src={designImage} fixed />
      <View style={styles.column}>
        {tools.map((item, index) =>
          item && item.tool.imagePath ? (
            <View
              style={{ ...styles.row, ...styles.imageContainer }}
              key={index}
            >
              {item.tol && (
                <Text style={styles.tolDepth}>TOL @ {bhaInfo?.tol} MD ft</Text>
              )}
              <>
                {item.tol ? (
                  <>
                    <View
                      style={{
                        ...styles.imageLeftTOL,
                        borderTop: "1px solid black",
                      }}
                    />
                    <View style={styles.lineImageLeftTOL} />
                  </>
                ) : item.isUnderTol && startIndex >= 0 && index > startIndex ? (
                  <>
                    <View
                      style={
                        tools.length - 1 === index
                          ? {
                              ...styles.imageLeftTOL,
                              ...styles.lineImageLastTOL,
                            }
                          : styles.imageLeftTOL
                      }
                    />
                    <View
                      style={
                        tools.length - 1 === index
                          ? {
                              ...styles.lineImageLeftTOL,
                              ...styles.lineImageLastTOL,
                            }
                          : styles.lineImageLeftTOL
                      }
                    />
                  </>
                ) : null}
                <Image
                  cache
                  style={item.tol ? styles.toolTol : styles.tool}
                  src={`${process.env.REACT_APP_SERVER}${item.tool.imagePath}`}
                  fixed
                />
              </>
              {item.tol ? (
                <>
                  <View
                    style={{
                      ...styles.imageRightTOL,
                      borderTop: "1px solid black",
                    }}
                  />
                  <View style={styles.lineImageRightTOL} />
                </>
              ) : item.isUnderTol && startIndex >= 0 && index > startIndex ? (
                <>
                  <View
                    style={
                      tools.length - 1 === index
                        ? {
                            ...styles.imageRightTOL,
                            ...styles.lineImageLastTOL,
                          }
                        : styles.imageRightTOL
                    }
                  />
                  <View
                    style={
                      tools.length - 1 === index
                        ? {
                            ...styles.lineImageRightTOL,
                            ...styles.lineImageLastTOL,
                          }
                        : styles.lineImageRightTOL
                    }
                  />
                </>
              ) : null}
              {item.tool.innerTools ? (
                <>
                  <Image
                    cache
                    style={item.tol ? styles.innerToolTol : styles.innerTool}
                    src={`${process.env.REACT_APP_SERVER}${item.tool.innerTools.imagePath}`}
                    fixed
                  />
                  <Text
                    key={index}
                    style={item.tol ? styles.lineInnerTol : styles.lineInner}
                    fixed
                  >
                    _________
                  </Text>
                  <Text
                    key={index}
                    style={
                      item.tol
                        ? styles.descriptionInnerTol
                        : styles.descriptionInner
                    }
                    fixed
                  >
                    {item.tool.osi
                      ? item.tool.description || item.tool.name
                      : item.tool.name}
                  </Text>
                </>
              ) : (
                <>
                  <Text
                    key={index}
                    style={item.tol ? styles.lineTol : styles.line}
                    fixed
                  >
                    _________
                  </Text>
                  <Text
                    key={index}
                    style={
                      item.tol ? styles.descriptionTol : styles.description
                    }
                    fixed
                  >
                    {item.tool.osi
                      ? item.tool.description || item.tool.name
                      : item.tool.name}
                  </Text>
                </>
              )}
            </View>
          ) : (
            ""
          )
        )}
      </View>
    </Page>
  );
};

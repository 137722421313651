import React from "react";
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { DataProps, WbdItemProps } from "../../../../interfaces/interfaces";
import wbdImage from "../../../../utils/images/onepage_vertical.png";
import espImage from "../../../../utils/images/ESP.jpg";
import rodPumpImage from "../../../../utils/images/SRP.jpg";
import PCPImage from "../../../../utils/images/PCP.png";
import gasLiftImage from "../../../../utils/images/GL.jpg";
import designImageESP from "../../../../utils/images/esp_design.png";
import designImageSRP from "../../../../utils/images/srp_design.png";
import designImageGL from "../../../../utils/images/gas_lift_design.png";
import designImagePCP from "../../../../utils/images/pcp_design.png";

const styles = StyleSheet.create({
  body: {
    margin: 0,
  },
  image: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    height: "100%",
    width: "100%",
  },
  sla: {
    position: "relative",
    top: 48,
    left: 152,
    width: "50px",
  },
  slaGL: {
    position: "relative",
    top: 44,
    left: 157,
    width: "28px",
  },
  slaPCP: {
    position: "relative",
    top: 43,
    left: 152,
    width: "39px",
  },
  designSRP: {
    position: "relative",
    top: 67,
    left: 166,
    width: "10px",
  },
  designESP: {
    position: "relative",
    top: 65,
    left: 162,
    width: "18px",
  },
  designGL: {
    position: "relative",
    top: 64,
    left: 165,
    width: "15px",
  },
  designPCP: {
    position: "relative",
    top: 60,
    left: 144,
    width: "55px",
  },
  sl: {
    position: "relative",
    top: 65,
    left: 146,
    width: "50px",
  },
  esp: {
    position: "relative",
    top: 65,
    left: 146,
    width: "50px",
  },
  toolPCP: {
    position: "relative",
    top: 55,
    left: 146,
    width: "50px",
  },
  toolPCPTol: {
    position: "relative",
    top: 55,
    left: 87.5,
    width: "50px",
  },
  tool: {
    position: "relative",
    top: 60,
    left: 146,
    width: "50px",
  },
  toolTol: {
    position: "relative",
    top: 60,
    left: 87.5,
    width: "50px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  imageContainer: {
    position: "relative",
    width: "auto", // Set your image width
    height: "auto", // Set your image height
  },
  line: {
    position: "relative",
    top: 60,
    left: 150,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  lineTol: {
    position: "relative",
    top: 60,
    left: 90,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  description: {
    position: "relative",
    top: 60,
    left: 160,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
  },
  descriptionSN: {
    position: "relative",
    top: 70,
    left: 200,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionTol: {
    position: "relative",
    top: 60,
    left: 100,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
  },
  lineSN: {
    position: "relative",
    top: 70,
    left: 190,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  innerTool: {
    position: "relative",
    top: 65,
    left: 95,
    width: "50px",
    alignItems: "center",
    justifyContent: "center",
  },
  lineInner: {
    position: "relative",
    top: 60,
    left: 100,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionInner: {
    position: "relative",
    top: 60,
    left: 110,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
  },
  innerToolTol: {
    position: "relative",
    top: 65,
    left: 36.5,
    width: "50px",
    alignItems: "center",
    justifyContent: "center",
  },
  imageLeftTOL: {
    position: "absolute",
    top: 0,
    left: "34%", // Centra la línea horizontalmente
    width: "7px", // Ajusta el grosor de la línea
    height: "100%",
    backgroundColor: "white", // Color de la línea
    transform: "translate(-50%, 100%)", // Centra la línea horizontalmente en la posición especificada
  },
  lineImageLeftTOL: {
    position: "absolute",
    top: 0,
    left: "35%", // Centra la línea horizontalmente
    width: "1px", // Ajusta el grosor de la línea
    height: "100%",
    backgroundColor: "black", // Color de la línea
    transform: "translate(-50%, 100%)", // Centra la línea horizontalmente en la posición especificada
  },
  imageLeftTOLLast: {
    position: "absolute",
    top: 0,
    left: "34%", // Centra la línea horizontalmente
    width: "7px", // Ajusta el grosor de la línea
    height: "100%",
    backgroundColor: "white", // Color de la línea
    transform: "translate(-50%, 100%)", // Centra la línea horizontalmente en la posición especificada
  },
  lineImageLeftTOLLast: {
    position: "absolute",
    top: 0,
    left: "35%", // Centra la línea horizontalmente
    width: "1px", // Ajusta el grosor de la línea
    height: "100%",
    backgroundColor: "black", // Color de la línea
    transform: "translate(-50%, 100%)", // Centra la línea horizontalmente en la posición especificada
  },
  lineImageLastTOL: {
    height: "100%",
  },
  imageRightTOL: {
    position: "absolute",
    top: 0,
    left: "39.2%", // Centra la línea horizontalmente
    width: "5.5px", // Ajusta el grosor de la línea
    height: "100%",
    backgroundColor: "white", // Color de la línea
    transform: "translate(-50%, 100%)", // Centra la línea horizontalmente en la posición especificada
  },
  lineImageRightTOL: {
    position: "absolute",
    top: 0,
    left: "39%", // Centra la línea horizontalmente
    width: "1px", // Ajusta el grosor de la línea
    height: "100%", // Asegura que la línea cubra toda la altura de la pantalla
    backgroundColor: "black", // Color de la línea
    transform: "translate(-50%, 100%)", // Centra la línea horizontalmente en la posición especificada
  },
  imageRightTOLLast: {
    position: "absolute",
    top: 0,
    left: "39.2%", // Centra la línea horizontalmente
    width: "5.5px", // Ajusta el grosor de la línea
    height: "100%",
    backgroundColor: "white", // Color de la línea
    transform: "translate(-50%, 100%)", // Centra la línea horizontalmente en la posición especificada
  },
  lineImageRightTOLLast: {
    position: "absolute",
    top: 0,
    left: "39%", // Centra la línea horizontalmente
    width: "1px", // Ajusta el grosor de la línea
    height: "100%", // Asegura que la línea cubra toda la altura de la pantalla
    backgroundColor: "black", // Color de la línea
    transform: "translate(-50%, 100%)", // Centra la línea horizontalmente en la posición especificada
  },
  lineInnerTol: {
    position: "relative",
    top: 60,
    left: 40,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionInnerTol: {
    position: "relative",
    top: 60,
    left: 50,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  tolDepth: {
    position: "relative",
    top: 60,
    left: 70,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  tolDepthAfterBottom: {
    position: "relative",
    top: 100,
    left: 70,
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export const WbdFirstPageGenVert = ({
  tools,
  slaId,
  data: {
    basicInfo: { mdDepth, bhaInfo },
    lastBottom,
  },
}: {
  tools: WbdItemProps[];
  slaId: number;
  data: DataProps;
}) => {
  const sla =
    slaId === 0 || slaId === 1
      ? styles.sla
      : slaId === 2
      ? styles.slaGL
      : styles.slaPCP;
  const slaImage =
    slaId === 0
      ? rodPumpImage
      : slaId === 1
      ? espImage
      : slaId === 2
      ? gasLiftImage
      : PCPImage;
  const designImage =
    slaId === 0
      ? designImageSRP
      : slaId === 1
      ? designImageESP
      : slaId === 2
      ? designImageGL
      : designImagePCP;
  const design =
    slaId === 0
      ? styles.designSRP
      : slaId === 1
      ? styles.designESP
      : slaId === 2
      ? styles.designGL
      : styles.designPCP;
  const startIndex = tools.findIndex((item) => item.tol === true);
  return (
    <Page style={styles.body}>
      <Image cache style={styles.image} src={wbdImage} fixed />
      <Image cache style={sla} src={slaImage} fixed />
      <Image cache style={design} src={designImage} fixed />
      <View style={styles.column}>
        {tools.map((item, index) =>
          item && item.tool.imagePath ? (
            <View
              style={{ ...styles.row, ...styles.imageContainer }}
              key={index}
            >
              {item.tol && (
                <Text style={styles.tolDepth}>TOL @ {bhaInfo?.tol} MD ft</Text>
              )}
              <>
                {item.tol ? (
                  <>
                    <View
                      style={{
                        ...styles.imageLeftTOL,
                        borderTop: "1px solid black",
                      }}
                    />
                    <View style={styles.lineImageLeftTOL} />
                  </>
                ) : item.isUnderTol &&
                  startIndex >= 0 &&
                  index > startIndex &&
                  index !== tools.length - 1 ? (
                  <>
                    <View
                      style={
                        tools.length - 1 === index
                          ? {
                              ...styles.imageLeftTOL,
                              ...styles.lineImageLastTOL,
                            }
                          : styles.imageLeftTOL
                      }
                    />
                    <View
                      style={
                        tools.length - 1 === index
                          ? {
                              ...styles.lineImageLeftTOL,
                              ...styles.lineImageLastTOL,
                            }
                          : styles.lineImageLeftTOL
                      }
                    />
                  </>
                ) : (
                  <>
                    {item.isUnderTol &&
                    index > startIndex &&
                    index === tools.length - 1 ? (
                      <>
                        <View style={styles.imageLeftTOLLast} />
                        <View style={styles.lineImageLeftTOLLast} />
                      </>
                    ) : null}
                  </>
                )}

                <Image
                  key={index}
                  cache
                  style={
                    slaId === 3
                      ? item.tol
                        ? styles.toolPCPTol
                        : styles.toolPCP
                      : item.tol
                      ? styles.toolTol
                      : styles.tool
                  }
                  src={`${process.env.REACT_APP_SERVER}${item.tool.imagePath}`}
                  fixed
                />
              </>
              {item.tol ? (
                <>
                  <View
                    style={{
                      ...styles.imageRightTOL,
                      borderTop: "1px solid black",
                    }}
                  />
                  <View style={styles.lineImageRightTOL} />
                </>
              ) : item.isUnderTol &&
                startIndex >= 0 &&
                index > startIndex &&
                index !== tools.length - 1 ? (
                <>
                  <View
                    style={
                      tools.length - 1 === index
                        ? {
                            ...styles.imageRightTOL,
                            ...styles.lineImageLastTOL,
                          }
                        : styles.imageRightTOL
                    }
                  />
                  <View
                    style={
                      tools.length - 1 === index
                        ? {
                            ...styles.lineImageRightTOL,
                            ...styles.lineImageLastTOL,
                          }
                        : styles.lineImageRightTOL
                    }
                  />
                </>
              ) : (
                <>
                  {item.isUnderTol &&
                  index > startIndex &&
                  index === tools.length - 1 ? (
                    <>
                      <View style={styles.imageRightTOLLast} />
                      <View style={styles.lineImageRightTOLLast} />
                    </>
                  ) : null}
                </>
              )}
              {item.tool.innerTools && item.tool.innerTools.imagePath ? (
                <>
                  <Image
                    cache
                    style={item.tol ? styles.innerToolTol : styles.innerTool}
                    src={`${process.env.REACT_APP_SERVER}${item.tool.innerTools.imagePath}`}
                    fixed
                  />
                  <Text
                    key={index}
                    style={item.tol ? styles.lineInnerTol : styles.lineInner}
                    fixed
                  >
                    _________
                  </Text>
                  <Text
                    key={index}
                    style={
                      item.tol
                        ? styles.descriptionInnerTol
                        : styles.descriptionInner
                    }
                    fixed
                  >
                    {item.tool.osi
                      ? item.tool.description || item.tool.name
                      : item.tool.name}
                    {slaId === 0 && index === 0 && mdDepth && mdDepth !== 0
                      ? ` @ ${mdDepth} MD ft`
                      : ""}
                    {lastBottom &&
                      index === tools.length - 1 &&
                      `@ ${lastBottom.toFixed(2)} MD ft`}
                  </Text>
                </>
              ) : (
                <>
                  <Text
                    key={index}
                    style={item.tol ? styles.lineTol : styles.line}
                    fixed
                  >
                    _________
                  </Text>
                  <Text
                    key={index}
                    style={
                      item.tol ? styles.descriptionTol : styles.description
                    }
                    fixed
                  >
                    {item.tool.osi
                      ? item.tool.description || item.tool.name
                      : item.tool.name}
                    {index === 0 && mdDepth && mdDepth !== 0
                      ? ` @ ${mdDepth} MD ft`
                      : ""}
                    {lastBottom &&
                      index === tools.length - 1 &&
                      `@ ${lastBottom.toFixed(2)} MD ft`}
                  </Text>
                </>
              )}
            </View>
          ) : (
            ""
          )
        )}
        {bhaInfo?.tol && lastBottom && bhaInfo.tol > lastBottom && (
          <Text style={styles.tolDepthAfterBottom}>
            TOL @ {bhaInfo?.tol} MD ft
          </Text>
        )}
      </View>
    </Page>
  );
};

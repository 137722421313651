import {
  Button,
  Divider,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import SimulatorContext from "../../../../context/SimulatorContext";
import styles from "../../../components.module.sass";

export const GasSimVortexForm = () => {
  const { vortexSimulatorValues, onUpdateGasSimulatorValues, onSimulateGas } =
    useContext(SimulatorContext);

  const handleUpdateValues = (item: string, value: any) => {
    onUpdateGasSimulatorValues(item, value, true);
  };
  return (
    <div className={styles.simulator}>
      <FormGroup>
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography mt={2} variant="h6">
              Input
            </Typography>
            <Divider variant="middle" style={{ marginTop: "1rem" }} />
            <Typography mt={2} variant="h6">
              Well Test
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Fluid Rate (stb/d)"
                  value={vortexSimulatorValues.bfpd}
                  style={{ marginTop: "1rem" }}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    handleUpdateValues("bfpd", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="BSW (%)"
                  value={vortexSimulatorValues.wCut}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    parseInt(event.target.value) >= 0 &&
                    parseInt(event.target.value) <= 100 &&
                    handleUpdateValues("wCut", event.target.value || 0)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Intake Pressure (psi)"
                  value={vortexSimulatorValues.pip}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    handleUpdateValues("pip", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="GOR (scf/stb)"
                  value={vortexSimulatorValues.gor}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    handleUpdateValues("gor", event.target.value)
                  }
                />
              </Grid>
            </Grid>

            <Divider variant="middle" style={{ marginTop: "1rem" }} />
            <Typography mt={2} variant="h6">
              Fluid and well Data
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Oil API"
                  style={{ marginTop: "1rem" }}
                  value={vortexSimulatorValues.oilApi}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    handleUpdateValues("oilApi", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Gas Sp Gravity"
                  value={vortexSimulatorValues.gasSP}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    handleUpdateValues("gasSP", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Water Sp. Gravity"
                  value={vortexSimulatorValues.waterSP}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  style={{ marginTop: "1rem" }}
                  onChange={(event) =>
                    handleUpdateValues("waterSP", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Intake Temp (F)"
                  value={vortexSimulatorValues.temperature}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  style={{ marginTop: "1rem" }}
                  onChange={(event) =>
                    handleUpdateValues("temperature", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="THP (psig)"
                  value={vortexSimulatorValues.thp}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  style={{ marginTop: "1rem" }}
                  onChange={(event) =>
                    handleUpdateValues("thp", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Reservoir P.(psig)"
                  value={vortexSimulatorValues.reservoirPressure}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  style={{ marginTop: "1rem" }}
                  onChange={(event) =>
                    handleUpdateValues("reservoirPressure", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Middle of Perfs (TVD ft)"
                  value={vortexSimulatorValues.middlePerfs}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  style={{ marginTop: "1rem" }}
                  onChange={(event) =>
                    handleUpdateValues("middlePerfs", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Pump Depth (TVD ft)"
                  value={vortexSimulatorValues.pumpDepth}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  style={{ marginTop: "1rem" }}
                  onChange={(event) =>
                    handleUpdateValues("pumpDepth", event.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography mt={2} variant="h6">
              Output
            </Typography>
            <Divider variant="middle" style={{ marginTop: "1rem" }} />
            <Typography mt={2} variant="h6">
              Fluid Properties
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Oil SPGr"
                  value={vortexSimulatorValues.oilSP}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  style={{ marginTop: "1rem" }}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="WOR"
                  value={vortexSimulatorValues.wor}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                {" "}
                <TextField
                  type="number"
                  label="GLR (SCF/STB)"
                  value={vortexSimulatorValues.glr}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Z Deviation Factor"
                  value={vortexSimulatorValues.deviationFactorZ}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                {" "}
                <TextField
                  type="number"
                  label="RS"
                  value={vortexSimulatorValues.rs}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Bo"
                  value={vortexSimulatorValues.bo}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Bg"
                  value={vortexSimulatorValues.bg}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Bw"
                  value={vortexSimulatorValues.bw}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Gas Density (lb/ft3)"
                  value={vortexSimulatorValues.gasDensity}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Liquid Density (lb/ft3)"
                  value={vortexSimulatorValues.liquidDensity}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Interfacial Tension (lb/sec2)"
                  value={vortexSimulatorValues.interfacialTension}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    handleUpdateValues("interfacialTension", event.target.value)
                  }
                />
              </Grid>
            </Grid>

            <Divider variant="middle" style={{ marginTop: "1rem" }} />
            <Typography mt={2} variant="h6">
              Production Data
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Pwf (psi)"
                  value={vortexSimulatorValues.pwf}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Productivity Index (stb/psi)"
                  value={vortexSimulatorValues.productivityIndex}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography mt={2} variant="h6">
              Rates at Surface Conditions
            </Typography>
            <Divider variant="middle" style={{ marginTop: "1rem" }} />
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Qo (STBOPD)"
                  value={vortexSimulatorValues.bopd}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorField}
                  disabled
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Qw (STBWPD)"
                  value={vortexSimulatorValues.bwpd}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorField}
                  disabled
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Qg (SCFD)"
                  value={vortexSimulatorValues.gasRate}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorField}
                  disabled
                />
              </Grid>
            </Grid>

            <Typography mt={2} variant="h6">
              Rates at Intake Conditions
            </Typography>
            <Divider variant="middle" style={{ marginTop: "1rem" }} />
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Qo_intake (BOPD)"
                  value={vortexSimulatorValues.qo_intake}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                {" "}
                <TextField
                  type="number"
                  label="Qw_intake (BWPD)"
                  value={vortexSimulatorValues.qw_intake}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                {" "}
                <TextField
                  type="number"
                  label="Qg_intake (cu ft/d)"
                  value={vortexSimulatorValues.qg_intake}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Free Gas Into Pump (%)"
                  value={vortexSimulatorValues.freeGasVortex}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <p>
                  <strong>Note</strong>
                  <br />
                  Gas % entering to pump above 10% indicate gassy conditions and
                  severe problems including gas locking can ocurr
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography mt={2} variant="h6">
              Mechanical Conditions
            </Typography>
            <Divider variant="middle" style={{ marginTop: "1rem" }} />
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Tubing Size (in)"
                  value={vortexSimulatorValues.tubingOd}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    handleUpdateValues("tubingOd", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  label="Gas Handler Size (in)"
                  value={vortexSimulatorValues.gasHandlerSize}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    handleUpdateValues("gasHandlerSize", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Retention Sections"
                  value={vortexSimulatorValues.retentionSecions}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    handleUpdateValues("retentionSecions", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Body OD (in)"
                  value={vortexSimulatorValues.bodyOd}
                  variant="outlined"
                  style={{ marginTop: "1rem" }}
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    handleUpdateValues("bodyOd", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Body ID (in)"
                  style={{ marginTop: "1rem" }}
                  value={vortexSimulatorValues.bodyId}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    handleUpdateValues("bodyId", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Dip Tube OD (in)"
                  style={{ marginTop: "1rem" }}
                  value={vortexSimulatorValues.ODdiptube}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    handleUpdateValues("ODdiptube", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Dip Tube ID (in)"
                  style={{ marginTop: "1rem" }}
                  value={vortexSimulatorValues.IDdiptube}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    handleUpdateValues("IDdiptube", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Casing ID (in)"
                  style={{ marginTop: "1rem" }}
                  value={vortexSimulatorValues.casingId}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    handleUpdateValues("casingId", event.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Divider variant="middle" style={{ marginTop: "1rem" }} />
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography mt={2} variant="body1">
                  Fluid Velocity outside the gas handler
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="CROSS SECTION AREA 1: Csg ID-GH OD (in2)"
                  style={{ marginTop: "1rem" }}
                  value={vortexSimulatorValues.crossSectional1}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography mt={2} variant="body1">
                  Fluid Velocity inside the gas handler
                </Typography>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="CROSS SECTION AREA 2: GH ID-Dip Tube OD (in2)"
                  value={vortexSimulatorValues.crossSectional2}
                  style={{ marginTop: "1rem" }}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography mt={2} variant="body1">
                  Fluid Velocity Inside the inner tube
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="CROSS SECTION AREA 3: Dip Tube ID (in2)"
                  style={{ marginTop: "1rem" }}
                  value={vortexSimulatorValues.crossSectional3}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Divider variant="middle" style={{ marginTop: "1rem" }} />
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Vsl_1 (ft/s)"
                  style={{ marginTop: "1rem" }}
                  value={vortexSimulatorValues.vsl_1}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Vsl_2 (ft/s)"
                  style={{ marginTop: "1rem" }}
                  value={vortexSimulatorValues.vsl_2}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Vsl_3 (ft/s)"
                  value={vortexSimulatorValues.vsl_3}
                  style={{ marginTop: "1rem" }}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2} sm={12} xs={12}>
            <Typography mt={2} variant="h6">
              Pressure Drop
            </Typography>
            <Divider variant="middle" style={{ marginTop: "1rem" }} />
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Viscosity (cp)"
                  style={{ marginTop: "1rem" }}
                  value={vortexSimulatorValues.viscocity}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    handleUpdateValues("viscocity", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Dip Tube Length (ft)"
                  style={{ marginTop: "1rem" }}
                  value={vortexSimulatorValues.dipTubeLength}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    handleUpdateValues("dipTubeLength", event.target.value)
                  }
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  type="number"
                  label="Pressure Drop-Dip Tube 1-1/4 (psi)"
                  value={vortexSimulatorValues.deltaP}
                  style={{ marginTop: "1rem" }}
                  variant="outlined"
                  className={styles.simulatorFieldVortex}
                  onChange={(event) =>
                    handleUpdateValues("deltaP", event.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormGroup>
      <Button
        variant="outlined"
        sx={{ mt: 5 }}
        onClick={() => onSimulateGas(true)}
      >
        Complete
      </Button>
    </div>
  );
};
